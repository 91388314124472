import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import Layout from './pages/Layout';
import * as routes from './routes';

import { ErrorHandlerProvider } from './providers/ErrorManagerContext';
import { DialogProvider } from './providers/Dialogs';

// Pages
import Home from './pages/Home';
import CreateLink from './pages/CreateLink';
import CreatePasteBin from './pages/CreatePasteBin';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<PageContext />}>
					<Route path={routes.home} element={<Home />} />
					<Route path={routes.createLink} element={<CreateLink />} />
					<Route path={routes.createPasteBin()} element={<CreatePasteBin />} />
					<Route path={routes.createPasteBin(':pin')} element={<CreatePasteBin />} />
					<Route path={routes.createQrCode} element={<CreatePasteBin showCreateButton={false} />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

function PageContext() {
	return (
		<ErrorHandlerProvider>
			<DialogProvider>
				<Layout>
					<Outlet />
				</Layout>
			</DialogProvider>
		</ErrorHandlerProvider>
	)
}

export default App;
