import * as api from 'common/routes';
api.setFrontend();
export * as api from 'common/routes';

export const home = api.frontend.home;
export const hello1 = '/hello';
export const hello2 = (who?: string) => ((who ?? null) == null) ? '/hello/:who' : `/hello/${encodeURIComponent(who!)}`;

export const createLink = api.frontend.createLink;
export const createPasteBin = api.frontend.createPasteBin;
export const createQrCode = '/createqrcode';
