import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { apiRequest } from "../utils";
import * as routes from "../routes";
import * as icons from "../media/icons";

function CreateLink() {
	const [busy, setBusy] = useState(false);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [txtUrl, setTxtUrl] = useState('');
	const [result, setResult] = useState(null as string | null);

	useEffect(() => {
		// set focus to textbox
		inputRef.current?.focus();
	}, []);

	async function createPin() {
		const url = txtUrl.trim();
		if (url === '')
			return;

		try {
			setBusy(true);

			const body: routes.api.tempLinks.CreateLinkRequest = { url };
			const result = await apiRequest<routes.api.tempLinks.CreateLinkResult>({
				method: 'POST',
				url: routes.api.tempLinks.link(),
				body,
				exceptionMessage: `Error while creating PIN number`,
			});
			setResult(JSON.stringify(result, null, '    '));
		} finally {
			setBusy(false);
		}
	}

	if (busy)
		return <icons.LoadingSpinnerPage />;

	return <>
		<InputGroup className="my-3 px-5">
			<InputGroup.Text>URL</InputGroup.Text>
			<Form.Control
				ref={inputRef}
				value={txtUrl}
				onChange={(e) => setTxtUrl(e.target.value)}
				onKeyDown={(e) => { if (e.key === 'Enter') createPin(); }}
			/>
			<Button className="input-group-text"
				onClick={() => createPin()}
			>
				Create
			</Button>
		</InputGroup>

		{(result !== null) &&
			<pre>{result}</pre>
		}
	</>
}

export default CreateLink;
