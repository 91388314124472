import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import * as qr from 'qrcode.react';

import { apiRequest, getExceptionDetails } from "../utils";
import * as routes from "../routes";
import * as icons from "../media/icons";

function CreatePasteBin({ showCreateButton = true }: {
	showCreateButton?: boolean,
}) {
	const { pin } = useParams<{ pin?: string }>();
	const inputRef = useRef<HTMLTextAreaElement | null>(null);
	const [busy, setBusy] = useState(false);
	const [pasteBinText, setPasteBinText] = useState('');
	const [passwordSize, setPasswordSize] = useState(40);
	const [passwordCharset, setPasswordCharset] = useState('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!"#$%&\'()*+,-./:;<=>?@[]^_{|}~');
	const [result, setResult] = useState(null as string | null);
	const [qrsize, setQrsize] = useState(200);
	const [qrCorrectionLevel, setQrCorrectionLevel] = useState<'L' | 'M' | 'Q' | 'H'>('L');
	const [qrBackground, setQrBackground] = useState('#ffffff');
	const [qrForeground, setQrForeground] = useState('#000000');

	useEffect(() => {
		// set focus to textbox
		inputRef.current?.focus();
	}, []);

	useEffect(() => {
		if (pin == null)
			// noop
			return;
		setBusy(true);
		(async () => {
			try {
				const { pasteBinText } = await apiRequest<routes.api.tempLinks.GetPasteBinResult>({
					method: 'GET',
					url: routes.api.tempLinks.getPasteBin(pin),
					exceptionMessage: `Error while fetchin content`,
				});
				setPasteBinText(pasteBinText);
			} catch (err) {
				setResult(await getExceptionDetails(err));
			} finally {
				setBusy(false)
			}
		})();
	}, [pin]);

	async function createPin() {
		if (pasteBinText.trim() === '')
			return;

		try {
			setBusy(true);

			const body: routes.api.tempLinks.CreatePasteBinRequest = { pasteBinText };
			const result = await apiRequest<routes.api.tempLinks.CreateLinkResult>({
				method: 'POST',
				url: routes.api.tempLinks.pasteBin(),
				body,
				exceptionMessage: `Error while creating PIN number`,
			});
			setResult(JSON.stringify(result, null, '    '));
		} finally {
			setBusy(false);
		}
	}

	if (busy)
		return <icons.LoadingSpinnerPage />;

	function generatePassword() {
		var str = '';
		var n = passwordCharset.length;
		for (var i = 0; i < passwordSize; ++i) {
			var j = (Math.random() * n) | 0;
			str += passwordCharset.charAt(j);
		}
		setPasteBinText(str);
	}

	function generateGuid() {
		const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = (Math.random() * 16) | 0;
			const v = (c === 'x') ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
		setPasteBinText(guid);
	}

	return <>
		<InputGroup className="my-3 px-5">
			<Form.Control as="textarea" placeholder="Text here"
				ref={inputRef}
				style={{ height: '10em' }}
				value={pasteBinText}
				onChange={(e) => setPasteBinText(e.target.value)}
			/>
			{showCreateButton &&
				<Button className="input-group-text"
					onClick={() => createPin()}
				>
					Create
				</Button>
			}
		</InputGroup>

		{(result !== null) &&
			<pre>{result}</pre>
		}

		<Container>
			<Row className="pb-3">
				<div className="col-sm-5 col-md-4 col-lg-3">
					<InputGroup>
						<Button className="input-group-text"
							onClick={() => generatePassword()}
						>
							gen. password
						</Button>
						<Form.Control type="number" width={10}
							title="size"
							value={passwordSize}
							onChange={(e) => setPasswordSize(parseInt(e.target.value))}
						/>
					</InputGroup>
				</div>
				<Col>
					<InputGroup>
						<Form.Control type="text"
							title="charset"
							value={passwordCharset}
							onChange={(e) => setPasswordCharset(e.target.value)}
						/>
					</InputGroup>
				</Col>
			</Row>
			<Row className="pb-3">
				<Col>
					<Button className="input-group-text"
						onClick={() => generateGuid()}
					>
						generate guid
					</Button>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col sm={7} xl={4}>
					<Row>
						<Form.Label className="col-4 text-end">
							size:
						</Form.Label>
						<Col>
							<Form.Control type="number"
								value={qrsize}
								onChange={(e) => setQrsize(parseInt(e.target.value))}
							/>
						</Col>
					</Row>
					<Row>
						<Form.Label className="col-4 text-end">
							background:
						</Form.Label>
						<Col>
							<Form.Control type="text"
								value={qrBackground}
								onChange={(e) => setQrBackground(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Form.Label className="col-4 text-end">
							foreground:
						</Form.Label>
						<Col>
							<Form.Control type="text"
								value={qrForeground}
								onChange={(e) => setQrForeground(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Form.Label className="col-4 text-end">
							correction:
						</Form.Label>
						<Col>
							<select className="form-control"
								value={qrCorrectionLevel}
								onChange={(e) => setQrCorrectionLevel(e.target.value as 'L' | 'M' | 'Q' | 'H')}
							>
								<option value="L">L: ~7%</option>
								<option value="M">M: ~15%</option>
								<option value="Q">Q: ~25%</option>
								<option value="H">H: ~30%</option>
							</select>
						</Col>
					</Row>
				</Col>

				<Col sm={5} xl={8}>
					<qr.QRCodeSVG
						value={pasteBinText}
						size={qrsize}
						bgColor={qrBackground}
						fgColor={qrForeground}
						marginSize={4}
						level={qrCorrectionLevel}
					/>
				</Col>
			</Row>
		</Container>
	</>
}

export default CreatePasteBin;
