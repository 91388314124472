import { AccessRight } from "./accessrights";
import { throwError } from "./utils";

let prefix: string;
export function getPrefix() { return prefix ?? throwError(`routes: 'prefix' is not set`); }
export const setFrontend = () => prefix = '/api';
export const setApi = () => prefix = '';

export namespace general {
	export const settings = () => `${prefix}/settings`;
	export interface SettingsResult {
		loggedIn: boolean;
		priviledges: AccessRight[];
	}
}

// on the frontend but need to be available to the API:
export namespace frontend {
	export const home = '/';
	export const createLink = '/createlink';
	export const createPasteBin = (existingPin?: string) => '/createpastebin' + ((existingPin == null) ? '' : `/${existingPin}`);
}

export namespace auth {
	export const login = (backUrl?: string) => `${prefix}/login`
		+ ((backUrl == null) ? `` : `?${loginBackUrlParm}=${encodeURIComponent(backUrl)}`);
	export const loginBackUrlParm = 'back-url';

	export const logout = () => `${prefix}/logout`;
	export type LogoutResult = {};
}

export namespace tempLinks {
	export const link = () => `${prefix}/link`;
	export type CreateLinkRequest = { url: string };
	export type CreateLinkResult = { pin: string };

	export const clear = (id: ':id') => `${prefix}/clear/${id}`;

	export const pasteBin = () => `${prefix}/pb`;
	export type CreatePasteBinRequest = { pasteBinText: string };
	export type CreatePasteBinResult = CreateLinkResult;

	export const getPasteBin = (pin: string) => `${prefix}/pastebin/${pin}`;
	export type GetPasteBinResult = CreatePasteBinRequest;
}

export namespace test {
	export const getWho = (id: 1 | 2 | 3) => `${prefix}/who/${id}`;
	export type GetWhoResult = { who: 'world' | 'bob' | 'alice' };

	export const getHello = () => `${prefix}/hello`;
	export type GetHelloRequest = { who: string };
	export type GetHelloResult = { answer: string }

	export const setHello = () => `${prefix}/hello`;
	export type SetHelloRequest = { who: string };
	export type SetHelloResult = {};
}
