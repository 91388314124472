import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import * as utils from "../utils";

// load bootstrap theme:
import 'bootstrap/dist/css/bootstrap.min.css';

const LayoutContext = createContext<{
	headerPlaceholder: null | JSX.Element,
	setHeaderPlaceholder: (v: null | JSX.Element) => void,
	isDarkMode: boolean,
	setIsDarkMode: (v: boolean) => void,
	breakPoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',  // cf. bootstrap breakpoints
	isMobile: boolean,
}>(null!);
export function useLayout() {
	return useContext(LayoutContext) ?? utils.throwError('useLayout must be used within a LayoutProvider');
}

function Layout() {
	const { isDarkMode } = useLayout();

	// watch for light/dark theme switch
	useEffect(() => {
		// set <body>'s class
		document.body.className = isDarkMode ? 'bg-black text-white' : 'bg-light';
		document.body.setAttribute('data-bs-theme', (isDarkMode ? 'dark' : 'light'));

		// update storage according to user's choice
		getIsDarkMode({ state: isDarkMode });
	}, [isDarkMode]);

	return <>

		<Outlet  // page body
		/>

	</>
}

/** returns 'true' if currently using "dark" theme */
function getIsDarkMode({ state }: { state: boolean | null }) {
	const storageKey = 'darkMode';
	const browser = window.matchMedia('(prefers-color-scheme: dark)').matches;
	const storageStr = localStorage.getItem(storageKey);
	const storage = (storageStr == null) ? null : (storageStr === 'true') ? true : false;

	const expected = state ?? storage ?? browser;

	if (expected === browser) {
		// equals default => no need to keep the storage variable
		if (storage != null)
			localStorage.removeItem(storageKey);
	} else {
		// need to save the user's choice in the local storage
		if ((storage == null) || (storage !== expected))
			localStorage.setItem(storageKey, expected ? 'true' : 'false');
	}

	return expected;
}

/** returns 'true' if < bootstrap breakPoint */
function getIsMobile(breakPoint: string) {
	const breakpointProperty = getComputedStyle(document.body).getPropertyValue(`--bs-breakpoint-${breakPoint}`).trim();
	const breakpointValue = parseInt(breakpointProperty, 10);
	const innerWidth = window.innerWidth;
	return (innerWidth < breakpointValue);
}

function LayoutProvider(_: {
	children: ReactNode,
}) {
	const breakPoint = 'lg';  // cf. bootstrap breakpoints
	const [headerPlaceholder, setHeaderPlaceholder] = useState<JSX.Element | null>(null);
	const [isDarkMode, setIsDarkMode] = useState(() => getIsDarkMode({ state: null }));
	const [isMobile, setIsMobile] = useState(() => getIsMobile(breakPoint));

	// watch for window resize
	useEffect(() => {
		const handleResize = () => {
			// update 'isMobile' state
			setIsMobile(getIsMobile(breakPoint));
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<LayoutContext.Provider value={{
			headerPlaceholder,
			setHeaderPlaceholder,
			isDarkMode,
			setIsDarkMode,
			breakPoint,
			isMobile,
		}}>
			<Layout />
		</LayoutContext.Provider>
	);
}

export default LayoutProvider;
